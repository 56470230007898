<template>
  <div
      class="slideshow-item"
      @click="handleClick"
  >
    <div class="slideshow-item__overlay"></div>
    <img class="image"
         :src="image.imageSource"
    >
  </div>
</template>

<script>
export default {
  name: 'GallerySlideshowItem',
  props: {
    image: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  methods: {
    handleClick() {
      this.$emit('image-clicked', this.index)
    }
  }
}
</script>

<style lang="scss">
.slideshow-item {
  height: 216px;
  width: 100%;
  border-radius: 0.9em;
  overflow: hidden;
  position: relative;
  &__overlay:before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #000000;
    content: '';
    opacity: 0;
    transition: opacity 0.2s linear;
  }
  &:hover {
    cursor: pointer;
    .slideshow-item__overlay:before {
      opacity: .5;
    }
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
</style>
