import { api } from '../../../../shared/services/backend-api'
import { UPLOADS_FOLDER_URL } from '../../../../shared/constants/backend'


const uploadPhotosToUnitGallery = (photos, unitID) => {
  
  const payload = new FormData()
  photos.forEach(({raw}) => {
    payload.append('photos[]', raw)
  })
  
  return api.post(`units/${unitID}/gallery`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  })
}

const fetchUnitGallery = async(unitID) => {
  const {data} = await api.get(`units/${unitID}/gallery`)
  
  return data.map(item => {
    return {
      ...item,
      imageSource: `${UPLOADS_FOLDER_URL}/${item.photo}`
    }
  })
}

const removeImage = (imageID) => {
  return api.delete(`photo/${imageID}`)
}

const updateImageInfo = (imageID, payload) => {
  return api.put(`photo/${imageID}`, payload)
}

export {
  uploadPhotosToUnitGallery,
  fetchUnitGallery,
  removeImage,
  updateImageInfo
}
