<template>
  <div class="text-center">
    <slot v-if="showTrigger">
      <VButton type="primary" @click="showMore">{{ $t('components.scroll_pagination.btn') }}</VButton>
    </slot>
  </div>
</template>

<script>
import VButton from '../v-button/VButton'

export default {
  name: 'VScrollPagination',
  components: {VButton},
  props: {
    totalCount: {
      type: Number,
      required: false,
      default: undefined
    },
    initialPage: {
      type: Number,
      required: false,
      default: 1
    },
    itemsPerPage: {
      type: Number,
      required: false,
      default: 10
    },
    items: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  watch: {
    // itemsToShow(val) {
    //
    // }
  },
  computed: {
    isServerSidePagination() {
      return this.totalCount !== undefined
    },
    totalItems() {
      if (this.isServerSidePagination) {
        return this.totalCount
      }
      return this.items.length
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    },
    showTrigger() {
      return this.currentPage < this.totalPages
    },
    lastIndexToShow() {
      return this.currentPage * this.itemsPerPage - 1
    },
    itemsToShow() {
      if (this.isServerSidePagination) {
        return this.items.slice(0, this.lastIndexToShow)
      }
      return []
    }
  },
  data() {
    return {
      currentPage: 1,
    }
  },
  created() {
    this.currentPage = this.initialPage || 1
  },
  methods: {
    incrementRow() {
      const INCREMENT_VALUE = 1
      this.currentPage += INCREMENT_VALUE
    },
    showMore() {
      this.incrementRow()
      this.$emit('show-more', this.currentPage)
    }
  },
}
</script>

<style scoped>

</style>
