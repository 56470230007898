<template>
  <div v-loading="isLoading">
    <div class="unit-actions">
      <can-i :permissions="[$options.ROLE_UNIT_ADMINISTRATION]">
        <v-button @click="goToManageGallery" icon="edit">
          {{ $t('page_unit_gallery.btn_manage') }}
        </v-button>
      </can-i>
    </div>
    <v-empty-state v-if="isEmptyState"
                   :subtitle="$t('page_unit.empty_states.unit_gallery.subtitle')"
                   :description="$t('page_unit.empty_states.unit_gallery.description')"
    />
    <div v-else
         class="gallery"
    >
      <GallerySlideshowItem
          v-for="(image, idx) in galleryImages"
          :key="idx"
          :image="image"
          :index="idx"
          @image-clicked="openImage"
      />
    </div>
    <VGallerySlideshow ref="gallery"
                       :images="imageSourceList"
                       :index="openedImage"
                       @update:index="openedImage = $event"
                       @close="openedImage = null">
      <div class="img-title">
        {{ selectedImageTitle }}
      </div>
    </VGallerySlideshow>
    <v-scroll-pagination :items-per-page="9"
                         :items="galleryImages"
    />
  </div>
</template>

<script>
import VGallerySlideshow from '../../../components/ui/v-gallery-slideshow/VGallerySlideshow'
import {fetchUnitGallery} from '../../../admin/units/shared/services/gallery-service'
import {
  NOT_FOUND_ROUTE_NAME,
  ADMIN_UNIT_GALLERY_ROUTE_NAME
} from '../../../shared/constants/app-routes-names'
import GallerySlideshowItem from './components/gallery-slideshow-item/GallerySlideshowItem'
import VButton from '../../../components/ui/v-button/VButton'
import CanI from '../../../auth/can-i/CanI'
import {ROLE_UNIT_ADMINISTRATION} from '../../../shared/constants/app-permissions'
import VScrollPagination from '../../../components/ui/v-scroll-pagination/VScrollPagination'
import VEmptyState from '../../../components/ui/v-empty-state/VEmptyState'

export default {
  name: 'UnitGallerySlideshow',
  ROLE_UNIT_ADMINISTRATION,
  components: {
    VEmptyState,
    VScrollPagination,
    CanI,
    VButton,
    GallerySlideshowItem,
    VGallerySlideshow
  },
  data() {
    return {
      isLoading: false,
      galleryImages: [],
      openedImage: null
    }
  },
  created() {
    this.fetchGalleryImages()
  },
  methods: {
    goToManageGallery() {
      const {unitID} = this.$route.params
      this.$router.push({
        name: ADMIN_UNIT_GALLERY_ROUTE_NAME,
        params: {
          unitID
        }
      })
    },
    openImage(idx) {
      this.openedImage = idx
    },
    fetchGalleryImages() {
      const {unitID} = this.$route.params
      this.isLoading = true
      fetchUnitGallery(unitID)
          .then((res) => {
            this.galleryImages = res
          })
          .catch(() => {
            this.$router.push({name: NOT_FOUND_ROUTE_NAME})
          })
          .finally(() => {
            this.isLoading = false
          })
    },
  },
  computed: {
    isEmptyState() {
      return !Boolean(this.galleryImages.length)
    },
    imageSourceList() {
      return this.galleryImages.map(({imageSource}) => imageSource)
    },
    selectedImageTitle() {
      if (this.openedImage !== null) {
        return this.galleryImages[this.openedImage].title
      }
      return ''
    },
  }
}
</script>

<style lang="scss">
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/grid-mixins";

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.7em;
  @include smartphone {
    grid-template-columns: repeat(2, 1fr);
  }
  @include xs-smartphone {
    grid-template-columns: repeat(1, 1fr);
  }
}

.vgs {
  .img-title {
    font-size: 1.2em;
    color: #FFFFFF;
    text-align: center;
  }

  &__container {
    overflow: visible !important;
  }
}

</style>
